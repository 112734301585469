import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"


export default function ProjectTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
    <Seo title={frontmatter.title} />
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
    <h1>
        <span className="block text-base text-center text-tefaf font-semibold tracking-wide uppercase">
          KPF.NRW
        </span>
      </h1> 
      <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        slug
        title
      }
    }
  }
`